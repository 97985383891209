.p-datatable-table {
  margin-top: 1em;
  border-color: #d8d8d8;
}
.p-card-content {
  padding: 0 !important;
}
.p-datatable-table > .p-datatable-thead > tr > th {
  padding: 0.5em !important;
  background-color: #e5e5e5 !important;
  color: black !important;
}
.p-datatable-table > .p-datatable-tbody > tr > td {
  padding: 0.5em !important;
}
.p-datatable-table > .p-datatable-tbody > tr {
  background-color: white !important;
  font-weight: 500 !important;
  color: black !important;
}
.p-datatable-table > .p-datatable-tbody > tr.p-row-odd {
  background-color: #f1f7ff !important;
  color: black !important;
}

.user-action {
  background-color: blue;
  color: white;
  width: 2em;
  height: 2em;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 4px;
}
