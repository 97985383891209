body {
  background-color: #f5f6fa;
}

.hide:not(.button) {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

.custom-icon-class {
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 13px;
  right: 6px;
  margin: 0;
  height: 100%;
  width: 2.67142857em;
  opacity: 0.5 !important;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.TopMenu {
  width: 195px;
}
.ui.menu:not(.vertical) .item {
  display: block !important;
  text-align: center;
  line-height: 64px;
}
.ui.menu .item > i.dropdown.icon {
  line-height: 64px;
}
.ui.pagination.menu .item {
  line-height: initial !important;
}

/* dropdown style */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  float: left;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.btn-group > .btn + .dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px;
}
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  float: left;
}
.btn .caret {
  margin-left: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}
ul.dropdown-menu li {
  cursor: pointer;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
ul.dropdown-menu li {
  cursor: pointer;
}

ul.dropdown-menu li span.red {
  color: red;
}

ul.dropdown-menu li span.green {
  color: green;
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicon-ok:before {
  content: "\e013";
}
.glyphicon-remove:before {
  content: "\e014";
}
.open > .dropdown-menu {
  display: block;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

@font-face {
  font-family: "Glyphicons Halflings";

  src: url("../fonts/glyphicons-halflings-regular.eot");
  src: url("../fonts/glyphicons-halflings-regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/glyphicons-halflings-regular.woff2") format("woff2"),
    url("../fonts/glyphicons-halflings-regular.woff") format("woff"),
    url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"),
    url("../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular")
      format("svg");
}

.modal-input-box-custom {
  width: 100%;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.modal-input-box-custom:focus-visible {
  border: 0px solid #85b7d9 !important;
}
.modal-input-box-custom:focus {
  border: 1px solid #85b7d9 !important;
}
.important-text {
  color: red;
  font-size: 12px;
  background-color: #fde5e5;
  margin: 13px;
  padding: 10px;
  border-radius: 5px;
}
