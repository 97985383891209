body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 4.5em;
  left: 0;
  background-color: #3377a3;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 2em;
}

.sidenav a {
  height: 3.5em;
  align-items: center;
  font-weight: 600;
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  width: 100%;
  font-size: 1em;
  color: #fff;
  display: flex;
  transition: 0.3s;
  border-top: 1px solid #004e63;
  border-bottom: 1px solid #004e63;
}

.sidenav a + a {
  border-top: 0;
}

.sidenav a:hover {
  background-color: #004e63;
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left 0.5s;
  min-height: 100vh;
  overflow-y: hidden;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
